<template>
  <div id="app" class="bg-dev_background tracking-wider">
    <router-view />
  </div>
</template>

<script>
  import Login from "./components/Login.vue";

  export default {
    name: "App",
    components: {
      Login,
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: 100vh;
    height: 100%;
  }
</style>
